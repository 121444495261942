.sidebar-wrapper {
  width: 256px;
  height: 100vh;
  transition: all 0.5s ease;
}

.sidebar-wrapper.close {
  width: 56px !important;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  background-color: white;
  width: 256px;
  height: 100%;
  transition: all 0.5s ease;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  z-index: 2;
}

.sidebar.close {
  width: 56px !important;
}

.sidebar-header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 54px;
  border-bottom: 1px rgba(0, 0, 0, 0.08) solid;
}

.sidebar-footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-grow: 1;
}

.list-group-item-wrapper {
  width: 100%;
  height: 4.44%;
  overflow: hidden;
  white-space: nowrap;
}

.list-group-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  border: 0px solid rgba(0, 0, 0, 0.125) !important;
  padding: 0.5rem 1rem;
  margin: 0.75px 0px;
  border-radius: 20px !important;
  color: #132880 !important;
  font-weight: 500;
}

.list-group-item:hover {
  color: #e66912 !important;
  background-color: #f8f9fa;
  font-weight: bold !important;
}

.list-group-item.active {
  color: #e66912 !important;
  background-color: #ffe0ae !important;
  font-weight: bold !important;
}

.list-group-item a {
  color: inherit;
}

.list-group-item-text {
  font-size: 0.875rem;
  text-transform: capitalize !important;
  padding: 0px 20px;
}

.list-group-title-text {
  color: #132880;
  font-size: 0.875rem;
  text-transform: uppercase !important;
  font-weight: bold;
}

.opened-list {
  display: block !important;
}

.collapsed-list {
  display: none !important;
}

.company-header-text {
  overflow: hidden;
  white-space: nowrap;
  color: #e66912;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.125rem;
  font-family: "Lexend", sans-serif;
}

.toggle-collaspe {
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: -15px;
  height: 30px;
  width: 30px;
  z-index: 2;
}

.custom-icon-wrapper {
  cursor: pointer;
  color: #132880;
}

.custom-icon-wrapper:hover {
  background-color: #ffe0ae;
  color: #e66912;
}
