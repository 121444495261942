.center-container {
  text-align: center;
  position: relative;
  width: 100%;
}

.row-container {
  position: relative;
  width: 100%;
}

.welcome {
  width: 100%;
  margin-top: 5%;
  opacity: 0.4;
  padding-bottom: 2%;
  position: absolute;
  font-weight: bold;
}

.images-container {
  margin-top: 10%;
  width: 100%;
  position: absolute;
}

.images {
  width: 25em;
  height: 25em;
  border-radius: 100%;
  opacity: 0.1;
}

.center {
  text-align: center;
}
