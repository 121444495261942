.login-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.error{
    font-size: 10px;
    color: red;
}

.title{
    text-align: center;
    margin-top: 100px;
    margin-bottom: 30px;
    font-weight: bold;
}

.center{
    text-align: center;
    /* margin-left: 38.4rem; */
    clear: left;
    float: left;
    position: relative;
    left: 50%;
}

@media screen and (min-width: 1400px) {
    .center{
        text-align: center;
        /* margin-left: 38.4rem; */
        clear: left;
        float: left;
        position: relative;
        left: 43%;
    }
}

@media screen and (min-width: 1800px) {
    .center{
        text-align: center;
        /* margin-left: 38.4rem; */
        clear: left;
        float: left;
        position: relative;
        left: 46%;
    }
}