.modal-text {
  font-size: 0.75rem;
}

.modal-header-text {
  font-size: 1.125rem;
}

.custom-btn {
  border-radius: 3px;
  border-color: #3c92dd;
  background-color: #3c92dd;
}